import request from '@/router/axios';
import {businessUrl} from "@/config/env"


//查询车间
export const getWorkShop = () => {
    return request({
        url: businessUrl + "department/selectWorkShopName?type=1",
        method: "get",
        params: {
            type: 1
        }
    })
}


//查询工序
export const getProcess = (departmentId) => {
    return request({
        url: businessUrl + "process/selectByWorkShopId",
        method: "get",
        params: {
            departmentId
        }
    })
}

//查询班制类型
export const getShiftSelectType = (departmentId) => {
    return request({
        url: businessUrl + "shift/selectType ",
        method: "get",
        params: {
            departmentId
        }
    })
}

//查询设备
export const getMachine = (workShopId, processId, workTime) => {
    return request({
        url: businessUrl + "successionManagement/selectStatusByWorkShopIdAndProcessId",
        method: "post",
        data: {
            workShopId,
            processId,
            workTime
        }
    })
}


//车间下的人员信息
export const getUserByWorkShopId = (workShopId) => {
    return request({
        url: businessUrl + "corpUsers/selectUserByWorkShopId",
        method: "get",
        params: {
            workShopId
        }
    })
}

//获得预估产量
export const getForecastYield = (machineId,strTime,strIotNumber,endTime,endIotNumber,productId) => {
    return request({
        url: businessUrl + "publicMethods/calculateProduction",
        method: "get",
        params: {
            machineId,
            strTime,
            strIotNumber,
            endTime,
            endIotNumber,
            productId
        }
    })
}

//班组信息回显
export const echoNowShiftInformation = (workShopId, processId, workTime,machineId) => {
    return request({
        url: businessUrl + "successionManagement/selectNowShiftInformation",
        method: "post",
        data: {
            workShopId,
            processId,
            workTime,
            machineId
        }
    })
}

//获取数据模板
export const getTemplateMachineDate = (workShopId, processId, workTime) => {
    return request({
        url: businessUrl + "successionManagement/templateSuccessionManagement",
        method: "post",
        data: {
            workShopId,
            processId,
            workTime
        }
    })
}


//单机台提交数据
export const commitMachineDate = (row,workTime) => {
    return request({
        url: businessUrl + "successionManagement/successionManagement",
        method: "post",
        data:{
            ...row,
            workTime,
        }
    })
}


//批量机台提交数据
export const commitMachineDateList = (data) => {
    return request({
        url: businessUrl + "successionManagement/successionManagementList",
        method: "post",
        data:data
    })
}







